<template>
  <div>
    <div class="box2">
      <div>


        <div class="addStyle">
          <el-upload
              class="addStyle"
              :action="`/system/resume/Resume_upload?userId=${userId}`"
              :data="{ userId: userId }"
              multiple
              :on-success="handleSuccess"
              :on-error="handleError">
            <div style="display: flex">
              <div class="addLogo"><img loading="lazy" src="../../assets/add.png" /></div>
              <div class="addTitle">上传附件</div>
            </div>

          </el-upload>

        </div>
        <div class="jobName">附件简历</div>
        <div class="recruit" v-if="recurite.length!==0">

          <div style="list-style: none;margin-top: 10px">
            <li  v-for="(recurite,index) in recurite" :key="index">
              <div class="name4" >
                <div class="pdfImg" @click="detailPdf(recurite)">    <img loading="lazy" src="../../assets/pdf.png"/></div>
                <div class="pdfTitle" @click="detailPdf(recurite)">
                  {{recurite.fileName}}
                </div>
                <div class="pdfTitle2" @click="detailPdf(recurite)">
                  .pdf
                </div>

                <div class="pdfDelete" @click="Pdfdelete(recurite)">
                  <i class="el-icon-delete-solid" style="color: #E85353FF"></i>
                </div>
              </div>
            </li>


          </div>
<!--          <div class="name3" @click="showUpload">-->
<!--            上传附件简历-->
<!--          </div>-->
        </div>
        <div class="recruit" v-else>
          <div
              style="
                  width: 120px;
                  height: auto;
                  font-size: 14px;
                  margin: 0 auto;
                "
          >
            <img loading="lazy" src="../../assets/none.png" alt="" />
          </div>
          <div class="name1" style="font-size: 12px">导入简历</div>
          <div class="name2" style="margin-top: 5px;font-size: 12px">
            请上传PDF格式的中文简历，大小不超过20M
          </div>

        </div>

        <uploadTip
            :dialogVisible="upload"
            @hideDialog="hideupload"
        ></uploadTip>

        <pdfScan
            :dialogVisible="pdf"
            :file-path="filePath"
            @hideDialog="hidethis"
        ></pdfScan>
      </div>
    </div>
  </div>



</template>


<script>
import uploadTip from "@/views/userlog/uploadTip.vue";
import pdfScan from "@/views/userlog/pdfScan.vue";
export default {

  name: "",

  props: [],

  components: { uploadTip,pdfScan},

  data() {

    return {

      filePath:"",
      resume:[],
      recurite:[],
      upload:false,
      pdf:false,
      trueResume:"",
      userId: localStorage.getItem("userId"),
      token:localStorage.getItem("access_token")
    }

  },
  created() {
    this.getResume()


  },

  methods: {
    handleSuccess(response) {
      // Handle successful upload here
      localStorage.setItem("resume",JSON.stringify(response))
      console.log(response)
      this.$message({
        message: response.msg, //提示的信息
        type: "warning", //类型是成功
        duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      });
      this.$emit('hideDialog')
      // Process 'response' data as needed
    },
    handleError(error) {
      // Handle upload error here
      console.error('Upload failed!', error);


      // Handle the error or display a message to the user
    },
    getResume(){
      this.$axios.get("/system/resume/"+this.userId,{
        headers: {
          Authorization: this.token,
        },
      })
          .then((res) => {
            console.log(res)
            this.recurite=res.data.rows
            this.recurite.forEach(function(element) {
              const parts = element.fileName.split('.');

              element.fileName= parts[0]; // Contains '微信图片_20230815090810'
            });


          })
          .catch((error) => {
            console.log(error);
          });
    },

    Pdfdelete(item){
      console.log(item)
      this.$axios.delete("/system/resume/"+item.id,{
        headers:{
          Authorization:this.token
        }
      }) .then((res) => {
        console.log(res)
        this.$message({
          message: res.data.msg, //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            this.getResume()
          },
        });



      })
          .catch((error) => {
            console.log(error);
          });
    },
    detailPdf(recurite){
      this.filePath=recurite.filePath
      this.pdf=true
      console.log(this.filePath)
    },
    hideupload(){
      this.upload=false
      this.getResume()
    },
    hidethis(){
      this.pdf=false
    },
    showUpload(){
      this.upload=true
    }
  },

  mounted() {



  }

}

</script>


<style type="text/scss" lang="scss" scoped>

.box2{
  position: relative;
  background: #FFFFFF;
  border-top: 1px solid #dcdbdb;
  //box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.05);
  opacity: 1;
  height: fit-content;
  padding: 15px 14px 50px 0 ;
  .recruit{

    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    padding:15px 10px 15px 10px;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    text-align: center;
    //border: 1px solid #FFFFFF;
  }
  .name4{
    margin: 6px 10px;
    display: flex;
    .pdfImg{
      width: 23px;
      position: absolute;
      height: 25px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
    .pdfTitle{
      text-align: left;
      cursor: pointer;
      height: 18px;
      margin-top: 6px;
      margin-left: 25px;
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
    .pdfTitle:hover{
      color: #E85353FF;
    }
    .pdfTitle2:hover{
      color: #E85353FF;
    }
    .pdfTitle2{
      cursor: pointer;
      margin-top: 6px;
      font-size: 14px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #222222;
      line-height: 18px;
      -webkit-background-clip: text;
    }
    .pdfDelete{
      position: absolute;
      right:15px;
      //display: none;
    }

  }
  .userSex{
    height: 16px;
    font-size: 14px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin-left: 6px;
  }
  .iconStyle{
    color:gray;
    width: 14px;
    height: 14px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .cancleStyle{
    margin-top: 20px;
    width: 128px;
    height: 44px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #BF0022;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color:  #BF0022;
    line-height: 20px;
    -webkit-background-clip: text;
  }
  .confirmStyle{

    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
    border: none;
    width: 128px;
    height: 44px;
    background-color: #BF0022;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .information{
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 48px;
    row-gap: 20px;

  }
  .title{
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 14px;
    -webkit-background-clip: text;
  }
  .titleInput{
    margin-top: 12px;
    .sexStyle1{
      width: 145px;
      height: 44px;
      background: #F8E9ECFF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #BF0022;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #BF0022;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .sexStyle2{
      width: 145px;
      height: 44px;
      background: #FDFDFDFF;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border:none;
      font-size: 16px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: gray;
      cursor: pointer;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .addStyle{
    cursor: pointer;
    position: absolute;
    display: flex;
    bottom: 0;
    background: #3162c3;
    width: 100vw;
    padding: 10px;
    justify-content: center;
    text-align: center;

  }
  .addStyle2{
    position: absolute;
    display: flex;
    right: 16px;
  }
  .addStyle1{
    position: absolute;
    display: flex;
    right:0;
  }
  .workStyle2{
    position: absolute;
    display: flex;
    right: 16px;
    top:19px;
  }
  .workStyle1{
    position: absolute;
    display: flex;
    right: 15px;
    top:19px;

  }
  .addLogo{
    width: 16px;
    height: 16px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .addTitle{
    width: fit-content;
    height: 16px;
    font-size: 12px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: white;
    line-height: 16px;
    margin-left:7px ;
  }
  .jobName{
    width: fit-content;
    height: 24px;
    font-size: 16px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #222222;
    line-height: 24px;
    padding: 0 0 0 15px;
    border-left: #3163c5 3px solid;
  }
  .jobName1{
    width: 80px;
    height: 24px;
    font-size: 20px;
    font-family: arial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: #222222;
    line-height: 24px;
  }
  .jobClass{
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    list-style: none;
    .jobStyle{
      position: relative;
      display: flex;
      height: fit-content;
      padding:8px 16px ;
      width: 100%;

    }
    .jobStyle1{
      position: relative;
      height: fit-content;
      width: 100%;
      margin-top: 12px;
    }
    .actions {
      opacity: 15;
      //visibility: hidden;
      //transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .jobStyle:hover{
      background: #F8F8F8;
    }
    .jobStyle:hover .actions {
      opacity: 1;
      visibility: visible;
    }
    .workName{
      height: 24px;
      font-size: 12px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 500;
      color: #222222;
      line-height: 24px;
    }
    .span{
      width: 1px;
      height: 12px;
      background: #CCCCCC;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 6px 10px;
    }



  }
  .workClass{
    cursor: pointer;
    width: 100vw;
    list-style: none;
    .workStyle{
      margin-top:10px;
      position: relative;
      width: 100%;
      height:auto;
      padding: 10px 0 10px 16px;
      background:  #F8F8F8;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      .workName{
        height: 24px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
      .span{
        width: 1px;
        height: 12px;
        background: #CCCCCC;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin: 6px 10px;
      }
      .workCompanyName{
        margin-top:4px;
        height: 16px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .workTime{
        height:22px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        padding-left: 6px;
        margin-left: 6px;
        //border-left: 1px solid gray;

      }
      .industry{
        margin-top: 4px;
        height: 16px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .indus{
        height: fit-content;
        word-wrap: break-word; /* 允许在单词内换行 */
        width: 70%;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      } .indusLunwen{
          height: fit-content;
          word-wrap: break-word; /* 允许在单词内换行 */
          width:70%;
          font-size: 12px;
          font-family: arial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
      .industry2{
        width: fit-content;
        height: 28px;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      .indusContent{
        word-wrap: break-word; /* 允许在单词内换行 */
        width: 100%;
        height: auto;
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
    .workActions {
      opacity: 156;
      display: block;
      //visibility: hidden;
      //transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .workStyle:hover{
      background: #F8F8F8;
    }
    .workStyle:hover .workActions {
      opacity: 1;
      margin-right:-55px;
      visibility: visible;
    }

  }

}
</style>
